import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from '@mui/material/Slider';
import FadeIn from 'react-fade-in';
import ProTip from './ProTip'; 
import validator from 'email-validator';
import moment from 'moment';
import confetti from "canvas-confetti";
import { Button, TextField, FormGroup, CardContent, CardActions, Input, Fade} from '@mui/material';
import TextTransition, { presets } from "react-text-transition";
import { useState } from 'react';
import { data } from 'autoprefixer'; 

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: '#000000',
    },
    primary: {
      main: '#FFFFFF', 
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  
});
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}
 

function ClaimPage(data){ 

  if(data.data.claimResponse){
    return( 
      <div className="p-20 rounded-xl bg-white shadow-md">
      <h4 className="mb-2 text-2xl font-semibold">{data.data.companyName}'s Response:</h4>
      <h4 className="mb-2 text-lg font-semibold">{data.data.claimResponse}.</h4>
      <div className="pt-10">
      <Button onClick={() => { 
          fetch(`https://us-central1-warranty-upsell.cloudfunctions.net/refileClaim?email=${data.email}&policyID=${data.policyID}`)
          .then(response => response.json()).then(x => {
            if(x.success){
              data.setClaimData(null)
            }
          }) 
      }}variant='contained'>Refile Claim</Button>
      </div>
      <div className="flex flex-wrap pt-5 items-center text-center">
          <p className="text-gray-500 text-xs text-center"> Policy was purchased on {new Date(data.data.inception).toString()} </p>
      </div>
      </div> 
    )
  }
  if(data.data.inProgress){
    console.log(data)
    return( 
      <div className="p-20 rounded-xl bg-white shadow-md">
      
      <h4 className="mb-2 text-2xl font-semibold">You have already filed a claim on {
      new Date(parseInt(data.data.claimDate) * 8.64e7)
      .toLocaleDateString()
      .split("T")[0]}.</h4>
      <h4 className="mb-2 text-lg font-semibold">Please wait for up to 7 days for {data.data.companyName} to respond.</h4>
       

      <div className="flex flex-wrap pt-5 items-center text-center">
          <p className="text-gray-500 text-xs text-center"> Policy was purchased on {new Date(data.data.inception).toString()} </p>
      </div>
  </div>  
    
    )
  }
  const [claimText, setClaimText] = useState(null);
  const [photos, setPhotos] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploadingPhotos, setUploadingPhotos] = useState(false)
  const [successPage, setSuccessPage] = useState(false)
  const [creatingClaim, setCreatingClaim] = useState(false)

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUploadingPhotos(true)
    const files = Array.from(e.target.files)
     
    const formData  = new FormData(); 
    for(let file in files) {  
      formData.append(file, files[file]); 
    } 
    const response = fetch('https://us-central1-warranty-upsell.cloudfunctions.net/uploadPhotos', {
      method: 'POST',
      body: formData,
      headers: {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.io)",
      }
    }).then(x => x.json()).then(x => {
      setPhotos(escape(x.files))
      setUploadingPhotos(false)
    }) 
  }
  

  return(   
    <div className="p-20 rounded-xl shadow-stone-300/100 hover:shadow-stone-500/50  shadow-md ">
 
        <h2 className="mb-2 text-xl font-semibold">Start a claim under your {data.data.warrantyName} {data.data.warrantyType.toLowerCase()} policy.</h2>
        <p className="text-base">Describe in detail your issue with {data.data.productName}. As a reminder, the clauses of the policy are as follows:</p>
        {JSON.parse(data.data.clauses).map(x => <p className="text-small"><i>{x}</i></p>)}
        <TextField value={claimText} onChange={(x) => setClaimText(x.target.value)} placeholder={`Describe your issue with ${data.data.productName}. Be sure to mention the specific clause that you believe should cover your case.`} fullWidth multiline rows={5} />
        <div className='pt-5'>
        <label className="pr-8 " htmlFor="contained-button-file">You may also upload up to <b>5</b> photos to help your claim 
   
 
</label> 
  <div className="mb-3 w-96"> 
    <input className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" onChange={handleFileSelected} type="file" id="photoUpload" multiple />
  </div>
</div> 

<div className='pt-10'>
  {uploading || uploadingPhotos ?
  uploading ?  
  <LoadingButton loadingIndicator="Please Wait" variant="outlined" fullWidth loading >Loading Photos. Please Wait</LoadingButton> :
  <LoadingButton loadingIndicator="Uploading Photos" variant="outlined" fullWidth loading >Submitting Claim. Please Wait</LoadingButton>
  :
  
  <Button disabled={creatingClaim} color="secondary" onClick={() => {

    if(claimText == null || claimText == ''){
      alert("Please add some text to your claim")
      return
    } 
 
    setCreatingClaim(true)
fetch('https://us-central1-warranty-upsell.cloudfunctions.net/createClaim', 

{
  method: "POST",
    body: JSON.stringify({email: data.email, policyID: data.policyID, claimText: claimText, photos: photos}),
    headers: {
      'Accept': 'application/json',
      "Content-Type": "application/x-www-form-urlencoded"
    },
}).then(x => x.json()).then(x =>{
    console.log(x)
    if(x.success){
      //data.setClaimData(null)
      congratulate()
      data.setShowSuccess(true) 
      setCreatingClaim(false)
    }
    //setUploading(false)
  })




}


} variant="outlined" fullWidth>{creatingClaim ? "Please Wait" : "Submit Claim"}</Button>


}
</div>
        <div className="flex flex-wrap items-center text-center">
            <p className="text-gray-500 text-xs text-center"> Policy was purchased on {new Date(data.data.inception).toString()} </p>
        </div>
    </div>  
 
  )
}

function HomePage(){
  const TEXTS = [ 
    "warranty",
    "extended warranty",
    "shipping coverage"
  ];

  const [index, setIndex] = React.useState(0);
  
  const [emailSignup, setEmailSignup] = useState(null)
  const [emailSignupErr, setEmailSignupErr] = useState(null)
  const [emailSignupSuccess, setEmailSignupSucess] = useState(null)

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return(
<div className="grid  grid-cols-1 xl:grid-cols-2 gap-0">
  <div className="container pt-16 xl:pt-36  text-3xl md:text-5xl font-bold">
  Offer
  <TextTransition
          text={ TEXTS[index % TEXTS.length] }
          springConfig={ presets.default }
        /> policies on your Shopify store.

        <div className='pt-8 pb-16'>Earn <u>thousands</u> in additional revenue.</div>
        {!emailSignupSuccess ?
        <FormGroup row>
 
        <TextField
          label="Join the waitlist"
          placeholder='hi@email.com' 
          value={emailSignup}
          onChange={(x) => {setEmailSignup(x.target.value)}}
          size="medium"
          error={emailSignupErr}
        /> <Button style={{'textTransform': "none"}} className={" bg-[#81592e]  text-md text-white font-sans font-bold"} variant="contained"
        onClick={() => { 
          setEmailSignupErr(false)
          if(!validator.validate(emailSignup)){
            setEmailSignupErr(true)
            return
          }
          fetch(`https://us-central1-warranty-upsell.cloudfunctions.net/saveEmail?email=${emailSignup}`)
          setEmailSignupSucess(true)
        }}>
          
Sign Up
             </Button>
 
        </FormGroup>
                     : <p>Thank you. We'll let you know when WeClaim launches.</p>
                    }
    </div> 
  <div className="container flex h-fit md:px-16 pt-16">
     <FadeIn>  
      <img className="w-full xl:w-3/4 md:ml-56 w-64 " src="/sneaker.png"></img> 
      
  </FadeIn>
  <div className="absolute scale-50 md:w-64 w-52 font-serif">
  <div id="policy-list" style={{"boxShadow": "0 5px 30px #000000"}} className="policy-list"><div style={{"fontSize": "25px"}} className="policy__header">
    <b style={{"fontSize": "20px"}}> Protect Your New Shoes</b>
    </div>
        <div className="policy__type" id="policy_type__Product_Insurance">
        <div className="policy__type__text">Shipping Coverage</div>
        
        
        <label className="policy">
          <div className="policy__offer">
          <div style={{"fontSize": "20px"}} className="policy__text">Sole Protector</div>
          <div id="policy__price__l07gafx1y55l20qr1vr" className="policy__price"><b>$5</b></div>
          </div>
          <div id="policy__question__l07gafx1y55l20qr1vr" className="policy__question">What does this cover?</div>
        </label>
        </div>
        
        <div className="policy__type" id="policy_type__Extended_Warranty">
        <div className="policy__type__text">Extended Warranty</div>
        
        
        <label className="policy">
          <div className="policy__offer">
          <div style={{"fontSize": "20px"}} className="policy__text">Spill Free</div>
          <div id="policy__price__l07gc0vyygelpshkxr" className="policy__price"><b>$55</b></div>
          </div>
          <div id="policy__question__l07gc0vyygelpshkxr" className="policy__question">What does this cover?</div>
        </label>
        </div>
        
        <div style={{"paddingTop": "10px", "textAlign": "center", "fontSize": "15px"}}>
          Warranties are offered by our shop
          </div> 
        </div> 
    
        </div>  


  
  </div> 
 

  
</div>

 

  )
}

function congratulate(){
  var end = Date.now() + (3 * 1000);
 
  var colors = ['#bb0000', '#ffffff'];

  (function frame() {
  confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors
  });
  confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors
  });

  if (Date.now() < end) {
      requestAnimationFrame(frame);
  }
  }());
}

function Claim(){ 
  
  const [claimData, setClaimData] = React.useState<any | null>(null);

  const [policyID, setPolicyID] = React.useState<any | null>(null);
  const [email, setEmail] = React.useState<any | null>(null);

  const [showError, setShowError] = React.useState<any | null>(null);
  const [showSuccess, setShowSuccess] =  React.useState<any | null>(null);
 
 
  return(
    
    claimData ? showSuccess ?  
    <div className="p-20 rounded-xl bg-white shadow-md">
    <h4 className="mb-2 text-3xl font-semibold">Your claim has successfully been filed.</h4>
    <h4 className="mb-2 text-lg font-semibold">Please wait for up to 7 days for {claimData.companyName} to respond.</h4>

    </div> 
    
    
    
    :<ClaimPage setShowSuccess={setShowSuccess} setClaimData={setClaimData} email={email} policyID={policyID} data={claimData} /> :
    <div className="flex justify-center  pb-56pt-10 ">
    
    <div className={`container-lg text-center text-lg bg-white font-bold rounded-lg shadow-xl shadow-stone-100/100 hover:shadow-stone-300/50 ${showError ? 'shadow-red-300' : null} p-10 `}>
      {showError ? "Policy not found" : "Find your policy"}
      <div className="pt-4">

      <TextField className="w-96" type="number" value={policyID} onChange={(x) => setPolicyID(x.target.value)} label="Policy ID" variant="outlined" /> 
      </div> 
      <div className="pt-4">

      <TextField className="w-96" value={email} onChange={(x) => setEmail(x.target.value)} label="Email" variant="outlined" /> 
      
</div>
 
<div className="pt-4">
 

<Button color="secondary" onClick={() => {  
   fetch(`https://us-central1-warranty-upsell.cloudfunctions.net/getClaim?email=${email.toLowerCase()}&policyID=${policyID}`)
   .then(response => response.json())
.then(response => { 
    if(!response.success){
      setShowError(true)
    }else{
      setShowError(false)
 
      let inceptionDateObj = moment.unix(response.inception / 1000)
      
      if(response.showStoreUninstalled){
        alert(`The store owner deleted WeClaim. You can contact the store directly regarding your warranty by contacting ${response.uninstalledClaimEmail}`)
        return
      }
      
      if(response.durationSetting.includes('year')){
          
        inceptionDateObj.add(response.duration, 'y')
      }else{
        inceptionDateObj.add(response.duration, 'd')
      }
 
      if(!(inceptionDateObj >= moment())){
        alert(`Your policy expired on ${inceptionDateObj.format("DD MMM YYYY")}. Please email help@weclaim.app if you have any questions.`)
        return
      }
      setClaimData(response)

    } 

})
}}>Next</Button> 
</div>
<p className='text-xs pt-6'>
By accessing your policy, you agree to our <a href='https://docs.google.com/document/d/16FdZJNGyJyC5AOc1wxPhZ_u4sAx8RQcG/edit?usp=sharing&ouid=113140434570485063634&rtpof=true&sd=true' target={'_blank'}>Terms of Service</a>
</p>
    </div>
    
    </div>



  ) 
    

	

 
}

export default function App() {

   
  const [currentPage, setCurrentPage] = React.useState('claim')
 
  return ( 
<ThemeProvider theme={theme}>

  <body className=''>
<div className="h-fit md:p-12 p-6 bg-right bg-cover font-base">
 
	<div className="w-full py-5  bg-[#fbeee6] container mx-auto px-8">
			
		<div className="w-full py-5 flex items-center justify-between">
			<a className="flex text-black items-center no-underline hover:no-underline font-bold text-3xl lg:text-5xl"> 
				 File A Claim
      </a>
			
			<div className="flex w-1/2 justify-end content-center">		
 
				<div className="h-10 p-2 md:h-auto md:p-4 ">
       {/* <button className="bg-white text-xl font-base hover:bg-indigo-50 text-black font-bold py-2 px-4 rounded" color="primary" onClick={() => {
        if(currentPage == 'home') setCurrentPage('claim')
        if(currentPage == 'claim') setCurrentPage('home')
          
          }} >{currentPage == 'home' ? 'File a Claim' : 'Back Home'}</button>
           */}
 
        </div>
        
			</div>
      
			
		</div>
    

	</div>
  <div className="w-full bg-white container mx-auto py-8">
    <Claim />
  {/* {currentPage == 'home' ? <HomePage /> : null}
 {currentPage == 'claim' ? <Claim /> : null} */}
 <div className="w-full pt-16 text-sm text-center md:text-left fade-in">
      <a className="text-black no-underline text-center " href="#">&copy; WeClaim 2025</a> 
 		</div> 
  
     <footer className="bg-[#81592e] text-white mt-20 text-center lg:text-left">

<div className="container p-6"> 
 <p className="flex justify-center items-center">
   <span className="mr-4" onClick={() => {window.open("https://docs.google.com/document/d/16Qstd6keabO2L_2YqoRvloRsh4Z38JjY/edit?usp=sharing&ouid=113140434570485063634&rtpof=true&sd=true")}}><u>Privacy Policy</u></span>
   <span className="mr-4" onClick={() => {window.open("https://docs.google.com/document/d/16FdZJNGyJyC5AOc1wxPhZ_u4sAx8RQcG/edit?usp=sharing&ouid=113140434570485063634&rtpof=true&sd=true")}}><u>Terms of Service</u></span>

 </p>
</div>

</footer>
 </div>
 
 </div>
 
 
 
 
</body>
</ThemeProvider>
  );
}
